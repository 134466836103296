import globalStylesUrl from '~/styles.css?url';
import faviconUrl from '~/assets/favicon.png';
import { withSentry } from '@sentry/remix';
import type { ReactNode } from 'react';

import type {
  ActionFunctionArgs,
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction,
  SerializeFrom,
} from '@remix-run/node';
import { json } from '@remix-run/node';

import toastStyles from 'react-toastify/dist/ReactToastify.css?url';
import { Links, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData, useRouteLoaderData } from '@remix-run/react';
import { uuid4 } from '@sentry/utils';
import invariant from 'tiny-invariant';

import { createEnterpriseIdSession, getEnterpriseId, getUserSessionId, logout } from '~/session/session.server';
import { type Profile, getProfileDetails } from '~/services/profile.server';
import { getUser } from '~/services/user.server';
import { type CurrentPlan, getPlan } from '~/services/subscription.server';
import {
  type EnterpriseMemberDetails,
  getEnterpriseMemberDetails,
  createOrganization,
} from '~/services/organizations.server';
import { GeneralErrorBoundary } from '~/components/error-boundary';
import type { Whoami } from '~/generated-types';
import { type ENV, getEnv } from '~/services/env.server';
import type { EnterpriseDetails, EnterpriseOwner } from './services/enterprise.server';
import { getEnterpriseDetails, getOwnEnterprises, packageEnterprises } from './services/enterprise.server';
import { combineHeaders } from '~/services/headers.server';

export { headers } from '~/services/headers.server';

export const links: LinksFunction = () => {
  return [
    { rel: 'stylesheet', href: globalStylesUrl },
    { rel: 'stylesheet', href: toastStyles },
    { rel: 'icon', type: 'image/png', href: faviconUrl },
  ];
};

export const meta: MetaFunction = () => [
  {
    charset: 'utf-8',
    title: 'Insomnia',
    viewport: 'width=device-width,initial-scale=1',
    description: 'Leading Open Source API Client, and Collaborative API Design Platform for GraphQL, and REST',
  },
];

type RootLoaderData = {
  ENV: ENV;
  user: Whoami | null;
  profile: Profile | null;
  currentPlan: CurrentPlan | null;
  enterpriseMemberDetails: EnterpriseMemberDetails | null;
  enterpriseDetails: EnterpriseDetails | null;
  ownEnterprises: EnterpriseOwner[];
  currentEnterpriseId: string;
};

export async function loader({ request }: LoaderFunctionArgs) {
  const requestId = uuid4();
  let headers = new Headers();
  headers.set('insomnia-request-id', requestId);

  const data: RootLoaderData = {
    ENV: getEnv(),
    user: null,
    profile: null,
    currentPlan: null,
    enterpriseMemberDetails: null,
    enterpriseDetails: null,
    ownEnterprises: [],
    currentEnterpriseId: '',
  };

  const sessionId = await getUserSessionId(request);

  if (sessionId) {
    const [user, profile, ownEnterprises] = await Promise.allSettled([
      getUser(request, requestId),
      getProfileDetails(request, requestId),
      getOwnEnterprises(request, requestId),
    ]);
    // Check if the user exists
    if (user.status === 'fulfilled') {
      data.user = user.value;

      // Check if the user has a plan
      if (user.value?.planId) {
        const currentPlan = await getPlan(request);

        // Check if the user is an enterprise member
        if (currentPlan.type === 'enterprise-member') {
          const enterpriseMemberDetails = await getEnterpriseMemberDetails(request);

          data.currentPlan = currentPlan;
          data.enterpriseMemberDetails = enterpriseMemberDetails;
        } else if (currentPlan.type === 'enterprise') {
          const enterpriseDetails = await getEnterpriseDetails(request);

          data.currentPlan = currentPlan;
          data.enterpriseDetails = enterpriseDetails;
        } else {
          data.currentPlan = currentPlan;
        }
      }
    }

    // Check if the user has a profile
    if (profile.status === 'fulfilled') {
      data.profile = profile.value;
    }

    if (ownEnterprises.status === 'fulfilled') {
      let currentEnterpriseId = (await getEnterpriseId(request)) || '';
      let currentEnterpriseRole;
      const packagedEnterprises = packageEnterprises(ownEnterprises.value);

      const curEnterprise = packagedEnterprises?.find(
        (enterprise: EnterpriseOwner) => enterprise.id === currentEnterpriseId,
      );
      if (currentEnterpriseId && curEnterprise) {
        currentEnterpriseId = curEnterprise.id;
        currentEnterpriseRole = curEnterprise.role;
      } else {
        currentEnterpriseId = packagedEnterprises[0]?.id;
        currentEnterpriseRole = packagedEnterprises[0]?.role;
      }

      data.ownEnterprises = packagedEnterprises;
      data.currentEnterpriseId = currentEnterpriseId;

      const cookieHeader = await createEnterpriseIdSession({
        request,
        enterpriseId: currentEnterpriseId,
        enterpriseRole: currentEnterpriseRole,
      });
      const finalHeaders = combineHeaders(headers, cookieHeader);
      headers = finalHeaders;
    }

    return json(data, { headers });
  }

  return json(data, { headers });
}

export async function action({ request }: ActionFunctionArgs) {
  const formData = await request.formData();
  const action = formData.get('action');

  invariant(action, 'Action is required');

  if (action === 'create-organization') {
    const organizationName = formData.get('organizationName') as string;
    invariant(organizationName, 'Organization name is required');

    return await createOrganization(request, {
      orgName: organizationName,
    });
  }

  if (action === 'logout') {
    await logout(request);
  }

  if (action === 'enterprise-change') {
    const enterpriseId = formData.get('enterpriseId') as string;
    const enterpriseRole = formData.get('enterpriseRole') as string;
    const headers = await createEnterpriseIdSession({ request, enterpriseId, enterpriseRole });
    return json({}, { headers });
  }

  return null;
}

export const useRootLoaderData = () => {
  const data = useRouteLoaderData('root') as SerializeFrom<typeof loader>;

  return data;
};

const RootLayout = ({ children, env }: { children: ReactNode; env?: Record<string, string> }) => {
  return (
    <html lang="en">
      <head>
        <Meta />
        <Links />
        <meta name="emotion-insertion-point" content="emotion-insertion-point" />
      </head>
      <body suppressHydrationWarning={true}>
        {children}
        <ScrollRestoration />
        {env && (
          <script
            dangerouslySetInnerHTML={{
              __html: `window.ENV = ${JSON.stringify(env)}`,
            }}
          />
        )}
        <Scripts />
      </body>
    </html>
  );
};

const Root = () => {
  const { ENV } = useLoaderData<typeof loader>();

  return (
    <RootLayout env={ENV}>
      <Outlet />
    </RootLayout>
  );
};

export const ErrorBoundary = () => {
  return (
    <RootLayout>
      <GeneralErrorBoundary />
    </RootLayout>
  );
};

export default withSentry(Root);
